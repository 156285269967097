var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _vm._m(0),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "header text-center" }, [
        _vm._v("ヤマラボでできること")
      ]),
      _vm._m(1),
      _vm._m(2),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-8" },
          [
            _c(
              "b-button",
              {
                staticClass: "info-btn",
                attrs: {
                  to: "/info/guide_record_cehckin_process",
                  size: "sm",
                  block: "",
                  variant: "outline-light"
                }
              },
              [_vm._v("記録作成やチェックインの方法")]
            )
          ],
          1
        )
      ])
    ]),
    _vm._m(3),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-8" },
          [
            _c(
              "b-button",
              {
                staticClass: "faq",
                attrs: { to: "/info/faq", block: "", variant: "outline-light" }
              },
              [_vm._v("よくあるご質問はこちら")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-img-box" }, [
      _c("div", { staticClass: "img-box" }, [
        _c("img", {
          attrs: { src: "https://devmaterial.yamalab.jp/img/top/slide_1.jpg" }
        })
      ]),
      _c("div", { staticClass: "title" }, [_vm._v("ご利用ガイド")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title text-center" }, [
              _vm._v("国内の約200岩場を掲載！")
            ]),
            _c("p", { staticClass: "card-text text-center" }, [
              _vm._v("全国の岩場のルートを掲載")
            ]),
            _c("div", { staticClass: "card-img text-center" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://devmaterial.yamalab.jp/img/guide/guide_img_1.png"
                }
              })
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title text-center" }, [
              _vm._v("関東エリアの画像トポ公開")
            ]),
            _c("p", { staticClass: "card-text text-center" }, [
              _vm._v("※掲載エリアは順次拡大予定")
            ]),
            _c("div", { staticClass: "card-img text-center" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://devmaterial.yamalab.jp/img/guide/guide_img_2.png"
                }
              })
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title text-center" }, [
              _vm._v("岩場近くのコンビニ・温泉表示")
            ]),
            _c("p", { staticClass: "card-text text-center" }, [
              _vm._v("外岩クライミングに便利な情報")
            ]),
            _c("div", { staticClass: "card-img text-center" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://devmaterial.yamalab.jp/img/guide/guide_img_3.png"
                }
              })
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title text-center" }, [
              _vm._v("クライミング活動の記録")
            ]),
            _c("p", { staticClass: "card-text text-center" }, [
              _vm._v("ルート単位の登攀記録作成が可能")
            ]),
            _c("div", { staticClass: "card-img text-center" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://devmaterial.yamalab.jp/img/guide/guide_img_4.png"
                }
              })
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title text-center" }, [
              _vm._v("岩場のチェックイン数の表示")
            ]),
            _c("p", { staticClass: "card-text text-center" }, [
              _vm._v("岩場の混雑状況を確認できる！")
            ]),
            _c("div", { staticClass: "card-img text-center" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://devmaterial.yamalab.jp/img/guide/guide_img_5.png"
                }
              })
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title text-center" }, [
              _vm._v("キャラクターバッジを集めよう！")
            ]),
            _c("p", { staticClass: "card-text text-center" }, [
              _vm._v("記録やチェックインが増えるとキャラが成長")
            ]),
            _c("div", { staticClass: "card-img text-center" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://devmaterial.yamalab.jp/img/guide/guide_img_6.png"
                }
              })
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "header text-center" }, [
        _vm._v("プランの比較")
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-10" }, [
          _c("table", { staticClass: "table table-bordered text-center" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "title", attrs: { scope: "col" } }),
                _c("th", { staticClass: "free", attrs: { scope: "col" } }, [
                  _vm._v("無料プラン")
                ]),
                _c(
                  "th",
                  { staticClass: "pay pay-top", attrs: { scope: "col" } },
                  [_vm._v("有料プラン")]
                )
              ])
            ]),
            _c("tbody", [
              _c("tr", [
                _c("th", { staticClass: "title", attrs: { scope: "row" } }, [
                  _vm._v("利用料")
                ]),
                _c("td", { staticClass: "free" }, [_vm._v("無料")]),
                _c("td", { staticClass: "pay" }, [
                  _vm._v("300円 / 月"),
                  _c("small", [_vm._v("(税込)")])
                ])
              ]),
              _c("tr", [
                _c("th", { staticClass: "title", attrs: { scope: "row" } }, [
                  _vm._v("画像トポの参照")
                ]),
                _c("td", { staticClass: "free" }, [_vm._v("御岳ボルダーのみ")]),
                _c("td", { staticClass: "pay" }, [
                  _c("i", { staticClass: "far fa-circle" })
                ])
              ]),
              _c("tr", [
                _c("th", { staticClass: "title", attrs: { scope: "row" } }, [
                  _vm._v("登攀記録の作成")
                ]),
                _c("td", { staticClass: "free" }, [
                  _c("i", { staticClass: "fas fa-times" })
                ]),
                _c("td", { staticClass: "pay" }, [
                  _c("i", { staticClass: "far fa-circle" })
                ])
              ]),
              _c("tr", [
                _c("th", { staticClass: "title", attrs: { scope: "row" } }, [
                  _vm._v("岩場のチェックイン")
                ]),
                _c("td", { staticClass: "free" }, [
                  _c("i", { staticClass: "fas fa-times" })
                ]),
                _c("td", { staticClass: "pay pay-bottom" }, [
                  _c("i", { staticClass: "far fa-circle" })
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }